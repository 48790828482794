import React, { Component, useState, useRef, useEffect } from "react";
import "./QRCodeGen.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import moment from "moment";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  PageHeader,
} from "antd";
import QRCodeStyling from "qr-code-styling";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, InputLabel } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import TablePagination from "@mui/material/TablePagination";
// import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import { Modal } from "@mui/material";
// import Typography from "@mui/material/Typography";
import logo from "../../images/new-logo.png";
import { logout } from "../../actions/loginActions";
import { countryCode, country_code_with_ISO, sgList } from "../../constants";
import countryCodes from "../../countryCodes";
const { SubMenu } = Menu;
import {
  saveGeneratedQRByUser,
  fetchQRGeneratedByUser,
} from "../../actions/general";
import { Troubleshoot } from "@mui/icons-material";

const cookies = new Cookies();

let QRCodeGenerate = (props) => {
  console.log({ props });
  const navigate = useNavigate();
  // const [qrCode, setQrCode] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [warningText, setWarningText] = useState("");
  const [pendingRequestModalOpen, setPendingRequestModalOpen] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [leadOwner, setLeadOwner] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [location, setLocation] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLeadMessage, setErrorLeadMessage] = useState("");
  const [errorCollegeMessage, setErrorCollegeMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [qrHistory, setQRHistory] = useState({});

  const qrCodeRef = useRef(null);
  // const qrCode = useRef(
  //     new QRCodeStyling({
  //         width: 300,
  //         height: 300,
  //         image:
  //             "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
  //         dotsOptions: {
  //             color: "#4267b2",
  //             type: "rounded",
  //         },
  //         imageOptions: {
  //             crossOrigin: "anonymous",
  //             margin: 20,
  //         },
  //     })
  // );
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
    overflowY: "auto",
  };
  const qrCode = useRef(
    new QRCodeStyling({
      type: "canvas",
      image: "https://cdn.pegasus.imarticus.org/images/imarticus-icon.svg",
      width: 400,
      height: 400,
      margin: 24,
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        saveAsBlob: true,
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 10,
      },
      dotsOptions: {
        type: "rounded",
        color: "#6a1a4c",
        roundSize: true,
        gradient: {
          type: "radial",
          rotation: 0,
          colorStops: [
            { offset: 0, color: "#14c89b" },
            { offset: 1, color: "#0e715a" },
          ],
        },
      },
      cornersSquareOptions: {
        type: "",
        color: "#035642",
        gradient: {
          type: "linear",
          rotation: 0.7853981633974483,
          colorStops: [
            { offset: 0, color: "#035642" },
            { offset: 1, color: "#048e6c" },
          ],
        },
      },
      cornersDotOptions: {
        type: "",
        color: "#035642",
        gradient: {
          type: "linear",
          rotation: 0.7853981633974483,
          colorStops: [
            { offset: 0, color: "#035642" },
            { offset: 1, color: "#048e6c" },
          ],
        },
      },
      backgroundOptions: {
        round: 0,
        color: "#ffffff",
      },
    })
  );

  let saveGeneratedQR = async (url) => {
    // setLoading(true);
    let { success, message, ...data } = await saveGeneratedQRByUser({
      url,
    });
    // setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    } else {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 60000);
    }
  };

  let fetchQRHistory = async () => {
    // setLoading(true);
    let { success, message, ...data } = await fetchQRGeneratedByUser({});
    // setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    } else {
      console.log({ QRCOUNT: data.qrCodeCounts || {} });
      setQRHistory(data.qrCodeCounts || {});
    }
  };

  useEffect(() => {
    if (qrCodeRef.current && generatedUrl) {
      qrCode.current.update({
        data: generatedUrl,
      });
      qrCode.current.append(qrCodeRef.current);
    }
  }, [generatedUrl]);

  // useEffect(() => {
  //   fetchQRHistory();
  // }, []);

  const handleCampaignIdChange = (event) => {
    setCampaignId(event.target.value);
  };
  const handleLeadOwnerChange = (event) => {
    setLeadOwner(event.target.value);
  };
  const handleCollegeNameChange = (event) => {
    setCollegeName(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  const handleGenerateUrl = () => {
    const uuidRegex =
      /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;

    const leadOwnerRegex = /^[A-Za-z]+\.[A-Za-z]+\d+$/;
    const collegeNameRegex = /^[A-Za-z0-9]+$/;

    if (!uuidRegex.test(campaignId)) {
      setErrorMessage(
        "Invalid Campaign ID format. Please enter a valid Campaign ID."
      );
      setGeneratedUrl("");
      return;
    }

    setErrorMessage("");
    if (leadOwner.length > 50) {
      setErrorCollegeMessage(
        "College Name cannot exceed 100 characters. Please shorten the name."
      );
      setGeneratedUrl("");
      return;
    }
    if (!leadOwnerRegex.test(leadOwner)) {
      setErrorLeadMessage(
        "Invalid Lead Owner format. Please enter a valid Lead Owner."
      );
      setGeneratedUrl("");
      return;
    }
    setErrorLeadMessage("");
    if (collegeName.length > 100) {
      setErrorCollegeMessage(
        "College Name cannot exceed 100 characters. Please shorten the name."
      );
      setGeneratedUrl("");
      return;
    }
    if (!collegeNameRegex.test(collegeName)) {
      setErrorCollegeMessage(
        "Invalid College Name format. Please enter a valid College Name."
      );
      setGeneratedUrl("");
      return;
    }

    setErrorCollegeMessage("");

    const url = `https://imarticus.org/Imarticus-EmploymentAssessmentTest/?c=${encodeURIComponent(
      campaignId
    )}&o=${encodeURIComponent(leadOwner)}&college=${encodeURIComponent(
      collegeName
    )}&location=${encodeURIComponent(location)}`;
    setGeneratedUrl(url);
    setCopyMessage("");
    saveGeneratedQR(url);

    setIsButtonDisabled(true);
    // setTimeout(() => {
    //   setIsButtonDisabled(false);
    // }, 60000);
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(generatedUrl)
      .then(() => setCopyMessage("URL copied to clipboard!"))
      .catch(() => setCopyMessage("Failed to copy URL."));
  };

  const handleDownloadQRCode = () => {
    qrCode.current.download({ extension: "png" });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  // const isButtonDisabled =
  //   !campaignId || !leadOwner || !collegeName || !location;
  const qrHistoryArray = Object.entries(qrHistory);
  const paginatedData = qrHistoryArray.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <div className="cashpayment">
        <h3>
          Generate ILNET QR codes by adding Campaign ID,&nbsp;Lead
          Owner,&nbsp;College Name
        </h3>
        <hr style={{ width: "100%", margin: "-1rem 0 2rem" }} />
        <div className="row">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPendingRequestModalOpen(true);
                fetchQRHistory();
              }}
            >
              Previous QR Requests
            </Button>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "space-between" }}>
          <h5>Enter the Campaign ID,&nbsp;Lead Owner,&nbsp;College Name</h5>

          <div className="row">
            <div
              className="col-md-8"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="row">
                <div className="col-md-3">
                  <TextField
                    id="campaignId"
                    value={campaignId}
                    onChange={handleCampaignIdChange}
                    label="Campaign Id"
                    variant="outlined"
                    error={!!errorMessage}
                    helperText={errorMessage}
                    placeholder="1b6e776f-1286-4925-b1dd-e409bc2f8864"
                  />
                  <p>
                    **Enter in this format -(e.g:
                    1b6e776f-1286-4925-b1dd-e409bc2f8864)
                  </p>
                </div>
                <div className="col-md-3">
                  <TextField
                    id="leadOwner"
                    value={leadOwner}
                    onChange={handleLeadOwnerChange}
                    label="Lead Owner"
                    variant="outlined"
                    error={!!errorLeadMessage}
                    helperText={errorLeadMessage}
                    placeholder="ravi.kumar1"
                  />
                  <p>**Enter in this format -(e.g: ravi.kumar1)</p>
                </div>
                <div className="col-md-3">
                  <TextField
                    id="collegeName"
                    value={collegeName}
                    onChange={handleCollegeNameChange}
                    label="College Name"
                    variant="outlined"
                    error={!!errorCollegeMessage}
                    helperText={errorCollegeMessage}
                    placeholder="FergussonCollege"
                  />
                  <p>**Enter in this format -(e.g: FergussonCollege)</p>
                </div>
                <div className="col-md-3">
                  {/* <TextField
                  id="collegeName"
                  value={collegeName}
                  onChange={handleCollegeNameChange}
                  label="College Name"
                  variant="outlined"
                  error={!!errorCollegeMessage}
                  helperText={errorCollegeMessage}
                  placeholder="FergussonCollege"
                /> */}
                  <InputLabel id="collegeName-label">
                    Select a Location
                  </InputLabel>
                  <Select
                    labelId="collegeName-label"
                    id="collegeName"
                    value={location}
                    onChange={handleLocationChange}
                    label="College Name"
                    style={{ width: "100%" }}
                  >
                    <MenuItem value="" disabled>
                      Select a location
                    </MenuItem>
                    {sgList.map((loc, index) => {
                      return (
                        <MenuItem key={index} value={loc}>
                          {loc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="col-md-3">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateUrl}
                    disabled={
                      isButtonDisabled ||
                      !campaignId ||
                      !leadOwner ||
                      !collegeName ||
                      !location
                    } // Disable button if any field is empty
                    style={{ marginTop: "20px" }}
                  >
                    Generate URL
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {generatedUrl && (
                <div style={{ marginTop: "20px" }}>
                  <strong>Generated URL:</strong>
                  <p>
                    <a
                      href={generatedUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCopyToClipboard();
                      }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                    >
                      {generatedUrl}
                    </a>
                  </p>
                  {copyMessage && (
                    <p style={{ color: "green" }}>{copyMessage}</p>
                  )}
                  <div ref={qrCodeRef} style={{ marginTop: "20px" }}></div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownloadQRCode}
                    style={{ marginTop: "20px" }}
                  >
                    Download QR Code
                  </Button>
                  <p style={{ paddingTop: "10px" }}>
                    *** Please test this QR code once before going for the event
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={pendingRequestModalOpen}
        onClose={() => setPendingRequestModalOpen(false)}
      >
        <div
          style={{
            padding: "16px",
            backgroundColor: "#fff",
            margin: "auto",
            width: "80%",
            outline: "none",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            QR Request History
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Campaign Id</TableCell>
                  <TableCell>Lead Owner</TableCell>
                  <TableCell>College Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Generated URL</TableCell>
                  <TableCell>Number of Students (OTP Verified) </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map(([url, value], index) => {
                  const urlParams = new URLSearchParams(url.split("?")[1]); // Extract query params
                  const campaignId = urlParams.get("c") || "N/A";
                  const leadOwner = urlParams.get("o") || "N/A";
                  const collegeName = urlParams.get("college") || "N/A";
                  const location = urlParams.get("location") || "N/A";

                  return (
                    <TableRow key={index}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{campaignId}</TableCell>
                      <TableCell>{leadOwner}</TableCell>
                      <TableCell>{collegeName}</TableCell>
                      <TableCell>{location}</TableCell>
                      <TableCell>
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent navigation on click
                            copyToClipboard(url); // Copy to clipboard
                          }}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "blue",
                          }}
                        >
                          {"Link"}
                        </a>
                      </TableCell>
                      <TableCell>{value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100]} // Options for rows per page
              component="div"
              count={qrHistoryArray.length} // Total number of rows
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPendingRequestModalOpen(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerate);
