import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import {
  fetchApplicationDetail,
  deleteUserInfoData,
  updateAssessmentAmount,
} from "../../actions/applicationFormAction";
import { sendPaymentLinkToUser } from "../../actions/general";
import Loader from "./Loader";
import CustomisedTextModal from "./CustomisedTextModal";
import ApprovalModal from "./ApprovalModal";
import { APPLICATION_STATUS, PAYMENT_ENDPOINT } from "../../constants";
import callicon from "../../images/call.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import envelopeicon from "../../images/envelope.svg";
import successicon from "../../images/success.svg";
import ViewScholarshipModal from "./ViewScholarshipModal";
import TransactionDetails from "./TransactionDetails";
import ApplyPromocodeModal from "./ApplyPromocodeModal";

import Form from "react-jsonschema-form";
import * as constants from "../../constants";
import currency from "../../currency";
import Axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class ViewApplicationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCustomisedTextModal: false,
      openScholarshipModal: false,
      currencySelected: "",
      app_id: this.props.applicationDetail.user_app_id,
      schemaObjects: [],
      transaction: null,
      loading: false,
      applicationOtpRequired: true,
      openApprovalModal: false,
      approvalType: 0,
      headIndex: 2,
      link_copy_text: "Copy Link",
      application_link_copy_text: "Copy Link",
      openApplyPromocodeModal: false,
      downloadInProgress: false,
    };
  }

  componentDidMount() {
    this.refresh();
  }
  refresh = () => {
    // this.props.deleteUserInfoData();
    this.props.fetchApplicationDetail({
      a_id: this.props.applicationDetail.a_id,
      crs_id: this.props.applicationDetail.crs_id,
      app_id: this.props.applicationDetail.user_app_id,
    });
    console.log({ props: this.props });
    if (this.checkVisibility("35")) {
      this.setState({ applicationOtpRequired: false });
    }
    //this.setState({schemaObjects: this.props.appreview.userinfo.schema_objects})
  };

  closeModal = () => {
    this.props.handleViewApplicationModalToggle(false);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      schemaObjects: nextProps.appreview.userinfo?.schema_objects,
    });
  }

  checkVisibility = (resource) => {
    if (!this.props.permissions) {
      return true;
    } else {
      if (!this.props.permissions[resource]) {
        return false;
      } else if (this.props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleApplicationStatus(val, e) {
    this.setState({
      user_data: {
        a_id: this.props.applicationDetail.a_id,
        crs_id: this.props.applicationDetail.crs_id,
        app_id: this.props.applicationDetail.user_app_id,
        u_name: this.props.appreview.userinfo.user_name,
        p_no: this.props.appreview.userinfo.phone_no,
        email: this.props.appreview.userinfo.email,
        o_stts: this.props.appreview.userinfo.app_status,
        new_status: val,
      },
      openCustomisedTextModal: true,
    });
  }

  handleApprovalModalToggle = (val) => {
    return this.setState({
      openApprovalModal: val,
    });
  };

  handleRevertApplicationStatus = () => {
    this.setState({
      user_data: {
        a_id: this.props.applicationDetail.a_id,
        crs_id: this.props.applicationDetail.crs_id,
        app_id: this.props.applicationDetail.user_app_id,
        u_name: this.props.appreview.userinfo.user_name,
        p_no: this.props.appreview.userinfo.phone_no,
        email: this.props.appreview.userinfo.email,
        o_stts: this.props.appreview.userinfo.app_status,
      },
      openApprovalModal: true,
      headIndex: 2,
      approvalType: 0,
    });
  };

  handleCustomisedTextModalToggle = (val) => {
    return this.setState({
      openCustomisedTextModal: val,
    });
  };

  handlModalToggle = (val) => {
    // this.props.handleViewApplicationModalToggle(false);
    return this.setState(
      {
        openCustomisedTextModal: val,
        openApprovalModal: val,
      },
      () => {
        this.refresh();
      }
    );
  };

  toggleSchemaObject = (idx) => {
    let tempSchema = this.state.schemaObjects;
    console.log({ tempSchema });
    tempSchema[idx].isOpen = !tempSchema[idx].isOpen;
    console.log({ tempSchema });
    this.setState({ schemaObjects: tempSchema });
  };

  fileUpload = (props) => {
    const { value, onChange } = props;
    console.log({ value });

    return (
      <div>
        {value && (
          <a href={value} className="fileName">
            {new URL(value).pathname.replace("/", "")}
          </a>
        )}
      </div>
    );
  };

  HandleViewScholarshipButton = (e) => {
    this.setState({
      openScholarshipModal: true,
    });
  };

  handleViewScholarshipModalToggle = (val) => {
    this.setState({
      openScholarshipModal: val,
    });
  };

  updateCurrency = (val) => {
    console.log({ val });
    this.setState({
      currencySelected: val,
    });
  };

  checkAttemptAvailability = (attempts, current, max, app_id, slug, quizId) => {
    console.log({ attempts, current, max, app_id, slug, quizId });
    if (attempts.some((att) => att.passed == true)) {
      return null;
    }
    if (attempts.length < current) {
      return (
        <div
          style={{
            marginTop: "8px",
            fontWeight: "700",
          }}
        >
          One allocated attempt is remaining for the student to attempt
        </div>
      );
    }
    if (attempts.length == current && attempts.length < max) {
      return (
        <Button
          onClick={() => {
            this.handleAttemptUpdate(app_id, slug, quizId);
          }}
          style={{
            marginTop: "8px",
            background: "#67C15E",
            color: "white",
          }}
        >
          Allocate One more attempt
        </Button>
      );
    }
    if (attempts.length == current && attempts.length == max) {
      return (
        <div
          style={{
            marginTop: "8px",
            fontWeight: "700",
          }}
        >
          No more attempts can be allocated, Student has exhausted all of the
          attempts.
        </div>
      );
    }
  };

  handleAttemptUpdate = (app_id, stage_slug, quiz_id) => {
    if (!window.confirm("Are you sure you want to update the attempt")) {
      return;
    }
    let fetch_data = {
      a_id: this.props.applicationDetail.a_id,
      crs_id: this.props.applicationDetail.crs_id,
      app_id: this.props.applicationDetail.user_app_id,
    };

    this.props.updateAssessmentAmount({
      app_id,
      stage_slug,
      quiz_id,
      fetch_data,
    });
  };

  checkApproveApplicationEnabled = (userinfo) => {
    console.log({ userinfo });
    let allQuizzes = [];
    let allQuizBlocksSlug = Object.keys(userinfo.quizzesBySlug);
    let allQuizSubmittedData = {};
    let isAllQuizzesPassed = true;

    Object.keys(userinfo.quizzesBySlug).forEach((slug) => {
      userinfo.quizzesBySlug[slug].forEach((qz) => {
        allQuizzes.push(qz.quizId);
      });
    });

    for (let i = 0; i < allQuizBlocksSlug.length; i++) {
      if (
        userinfo.form_data[allQuizBlocksSlug[i]] &&
        Object.keys(userinfo.form_data[allQuizBlocksSlug[i]]).length
      ) {
        allQuizSubmittedData = {
          ...allQuizSubmittedData,
          ...userinfo.form_data[allQuizBlocksSlug[i]],
        };
      }
    }

    for (let i = 0; i < allQuizzes.length; i++) {
      let isPassed = true;

      if (allQuizSubmittedData[allQuizzes[i].toString()]) {
        isPassed = allQuizSubmittedData[allQuizzes[i].toString()].some(
          (resp) => resp.passed == true
        );
      } else {
        isPassed = false;
      }

      if (!isPassed) {
        isAllQuizzesPassed = false;
        break;
      }
    }

    console.log({
      allQuizzes,
      allQuizBlocksSlug,
      isAllQuizzesPassed,
    });

    return isAllQuizzesPassed;
  };

  checkOtpApprovalAvailable = (userinfo) => {
    if (!this.state.applicationOtpRequired) {
      return false;
    }
    console.log({ userinfo });
    let allQuizzes = [];
    let allQuizBlocksSlug = Object.keys(userinfo.quizzesBySlug);
    let allQuizSubmittedData = {};
    let isAllQuizzesPassed = true;

    Object.keys(userinfo.quizzesBySlug).forEach((slug) => {
      userinfo.quizzesBySlug[slug].forEach((qz) => {
        allQuizzes.push(qz.quizId);
      });
    });

    for (let i = 0; i < allQuizBlocksSlug.length; i++) {
      if (
        userinfo.form_data[allQuizBlocksSlug[i]] &&
        Object.keys(userinfo.form_data[allQuizBlocksSlug[i]]).length
      ) {
        allQuizSubmittedData = {
          ...allQuizSubmittedData,
          ...userinfo.form_data[allQuizBlocksSlug[i]],
        };
      }
    }

    for (let i = 0; i < allQuizzes.length; i++) {
      let isPassed = true;

      if (allQuizSubmittedData[allQuizzes[i].toString()]) {
        isPassed = allQuizSubmittedData[allQuizzes[i].toString()].some(
          (resp) => resp.passed == true
        );
      } else {
        isPassed = false;
      }

      if (!isPassed) {
        isAllQuizzesPassed = false;
        break;
      }
    }

    console.log({
      allQuizzes,
      allQuizBlocksSlug,
      isAllQuizzesPassed,
    });

    return !isAllQuizzesPassed;
  };
  sendPaymentLink = async (installment) => {
    if (
      !window.confirm(
        "Are you sure you want to send the payment link to the user?"
      )
    ) {
      console.log("returning");
      return;
    }
    console.log("not returning");
    let { success, message, ...data } = await sendPaymentLinkToUser({
      app_id: this.props.applicationDetail?.user_app_id,
      installment,
    });
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Success! The payment link has been sent to the user.");
  };
  checkScholashipButtonVisibility = () => {
    let packageFound = this.props.appreview.userinfo.transaction_details
      ? this.props.appreview.crs_pkgs.find((pkg) => {
          pkg._id ==
            this.props.appreview.userinfo.transaction_details?.package_id;
        })
      : this.props.appreview.crs_pkgs[0];
    console.log({ props: this.props });
    console.log({
      packageFound,
      "!Boolean(packageFound?.blockScholarshipByAdvisor)": !Boolean(
        packageFound?.blockScholarshipByAdvisor
      ),
      "!(this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_TWO] ||this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_THREE] ||this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_FOUR])":
        !(
          this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_TWO] ||
          this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_THREE] ||
          this.props?.permissions?.[this.props?.resources?.OPS_LEVEL_FOUR]
        ),
    });
    return (
      !Boolean(packageFound?.blockScholarshipByAdvisor) ||
      this.props.permissions?.[this.props.resources?.OPS_LEVEL_TWO] ||
      this.props.permissions?.[this.props.resources?.OPS_LEVEL_THREE] ||
      this.props.permissions?.[this.props.resources?.OPS_LEVEL_FOUR]
      // true
    );
  };

  isPromocodeAvailable = () => {
    const transaction = this.props.appreview.userinfo.transaction_details;

    if (
      transaction &&
      (transaction.full_trxn_id ||
        transaction.installment_trxn_ids.length ||
        transaction.emi_trxn_ids.length)
    ) {
      return false;
    } else {
      return true;
    }
  };

  isApprovalAvailable = () => {
    let approval = true;
    const isPaymentBlockPresent = this.state.schemaObjects.some(
      (obj) => obj.type == 2
    );
    const transaction = this.props.appreview.userinfo.transaction_details;
    if (isPaymentBlockPresent) {
      if (
        transaction &&
        (transaction.full_trxn_id ||
          transaction.installment_trxn_ids.length ||
          transaction.emi_trxn_ids.length)
      ) {
        approval = false;
      }
    } else {
      if (
        transaction &&
        (transaction.reg_trxn_id ||
          transaction.application_trxn_id ||
          transaction.full_trxn_id ||
          transaction.installment_trxn_ids.length ||
          transaction.emi_trxn_ids.length)
      ) {
        approval = false;
      }
    }

    return approval;
  };

  getPaymentLink = () => {
    let link;
    if (this.props.appreview.userinfo.paymentCollectionType == 2) {
      link = this.props.appreview.userinfo.partnerPaymentLink;
    } else {
      let COURSE_PAYMENT_CHECKOUT_PAGE = PAYMENT_ENDPOINT;
      const trn = this.props.appreview.userinfo.transaction_details;
      if (
        this.props.appreview.userinfo.collectRegistrationFirst &&
        (!trn ||
          (trn.installment_trxn_ids?.length == 0 &&
            trn.emi_trxn_ids?.length == 0 &&
            !trn.reg_trxn_id &&
            !trn.application_trxn_id &&
            !trn.full_trxn_id))
      ) {
        COURSE_PAYMENT_CHECKOUT_PAGE += "/registration";
      } else {
        COURSE_PAYMENT_CHECKOUT_PAGE += "/payment";
      }

      link =
        COURSE_PAYMENT_CHECKOUT_PAGE +
        "?aid=" +
        this.props.applicationDetail.a_id +
        "&crs_pg_id=" +
        this.props.applicationDetail.crs_id +
        `&name=${this.props.appreview.userinfo.user_name}&email=${
          this.props.appreview.userinfo.applicationEmail ||
          this.props.appreview.userinfo.email[0]
        }&cphone=${this.props.appreview.userinfo.phone_no}&edit=false`;
    }

    return link;
  };

  getInstallmentLink = () => {
    let link;
    if (this.props.appreview.userinfo.paymentCollectionType == 2) {
      link = this.props.appreview.userinfo.partnerPaymentLink;
    } else {
      let COURSE_PAYMENT_CHECKOUT_PAGE = PAYMENT_ENDPOINT + "/installments";

      link =
        COURSE_PAYMENT_CHECKOUT_PAGE +
        "?aid=" +
        this.props.applicationDetail.a_id +
        "&crs_pg_id=" +
        this.props.applicationDetail.crs_id +
        `&name=${this.props.appreview.userinfo.user_name}&email=${
          this.props.appreview.userinfo.applicationEmail ||
          this.props.appreview.userinfo.email[0]
        }&cphone=${this.props.appreview.userinfo.phone_no}&edit=false`;
    }
    return link;
  };

  getApplicationLink = () => {
    let link;
    let COURSE_PAYMENT_CHECKOUT_PAGE = PAYMENT_ENDPOINT + "/apply";
    link =
      COURSE_PAYMENT_CHECKOUT_PAGE +
      `/${this.props.appreview.userinfo.crs_utag}?name=${
        this.props.appreview.userinfo.user_name
      }&email=${
        this.props.appreview.userinfo.applicationEmail ||
        this.props.appreview.userinfo.email[0]
      }&cphone=${this.props.appreview.userinfo.phone_no}${
        this.props.appreview.userinfo.location
          ? `&location=${this.props.appreview.userinfo.location}`
          : ""
      }&edit=false`;
    return link;
  };

  copyApplicationLink = () => {
    let link = this.getApplicationLink();
    navigator.clipboard.writeText(link);
    this.setState({ application_link_copy_text: "Copied" });
    console.log({ link });
  };

  checkResendMainPaymentPageButtonAvailable = () => {
    const transaction = this.props.appreview.userinfo.transaction_details;
    const isAvailable = !(
      transaction &&
      transaction.installmentPlanId &&
      transaction.installment_trxn_ids.length > 0
    );
    return isAvailable;
  };

  checkResendInstallmentPaymentPageButtonAvailable = () => {
    const transaction = this.props.appreview.userinfo.transaction_details;
    const isAvailable =
      transaction &&
      transaction.installmentPlanId &&
      ((transaction.updated_package.reg_price > 0 && transaction.reg_trxn_id) ||
        !transaction.updated_package.reg_price ||
        !transaction.package_snapshot.reg_price) &&
      ((transaction.updated_package.app_fees > 0 &&
        transaction.application_trxn_id) ||
        !transaction.updated_package.app_fees ||
        !transaction.package_snapshot.app_fees);

    return isAvailable;
  };

  handlApplyPromocodeModalToggle = (val) => {
    this.setState({
      openApplyPromocodeModal: val,
    });
  };

  handleApplicationPDFonClick = async (e) => {
    console.log(
      "this.props.appreview.userinfo :: ",
      this.props?.appreview?.userinfo,
      this.props?.appreview?.userinfo.user_app_id
    );
    this.setState({
      downloadInProgress: true,
    });
    await this.downloadApplicationReport(
      this.props?.appreview?.userinfo.user_app_id
    );
    this.setState({
      downloadInProgress: false,
    });
  };

  downloadApplicationReport = async (appId) => {
    console.log("appId :: ", appId);
    let url =
      constants.API_ENDPOINT +
      "/ops/fetchAllApplicationDocumentsProtected?appId=" +
      appId;
    var configs = {
      method: "GET",
      url,
      headers: {
        "x-access-token": cookies.get("at"),
      },
      responseType: "blob",
    };
    Axios(configs)
      .then((response) => {
        console.log("Response in fetchAllApplicationDocumentsProtected :: ", {
          response,
        });

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Extract filename from response headers (if available)
        let fileName = `FINAL_MERGED_${appId}.pdf`; // Default name
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/);
          if (match) {
            fileName = match[1];
          }
        }

        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch((err) => {
        console.log(err);
        window.alert(
          "There was some error performing this action! Please try again."
        );
      });
  };

  render() {
    console.log(this.props);
    if (this.props.appreview.arloading) {
      return <Loader loading={this.props.appreview.arloading} />;
    }

    if (this.props.appreview.userinfo) {
      return (
        <div>
          {this.state.openCustomisedTextModal ? (
            <CustomisedTextModal
              open={this.state.openCustomisedTextModal}
              handleCustomisedTextModalToggle={
                this.handleCustomisedTextModalToggle
              }
              handlModalToggle={this.handlModalToggle}
              user_data={this.state.user_data}
              scholarship={this.props.appreview.userinfo.scholarship_amount}
              packageId={
                this.props.appreview.userinfo.transaction_details
                  ? this.props.appreview.userinfo.transaction_details
                      .updated_package._id
                  : this.state.currencySelected
                  ? this.props.appreview.crs_pkgs.filter(
                      (pkg) => pkg.curr == this.state.currencySelected
                    )[0]._id
                  : this.props.appreview.crs_pkgs[0]._id
              }
              currency={
                this.props.appreview.userinfo.transaction_details
                  ? currency[
                      this.props.appreview.userinfo.transaction_details
                        .updated_package.curr
                    ].symbol
                  : this.state.currencySelected
                  ? currency[this.state.currencySelected].symbol
                  : currency[this.props.appreview.crs_pkgs[0].curr].symbol
              }
              packages={this.props.appreview.crs_pkgs}
              applicationDetail={this.props.applicationDetail}
            />
          ) : null}
          {this.state.openApplyPromocodeModal ? (
            <ApplyPromocodeModal
              open={this.state.openApplyPromocodeModal}
              handlApplyPromocodeModalToggle={
                this.handlApplyPromocodeModalToggle
              }
              applicationDetail={this.props.applicationDetail}
              discount={this.props.appreview.userinfo.advisorAppliedPromocode}
              transaction={this.props.appreview.userinfo.transaction_details}
              packages={this.props.appreview.crs_pkgs}
              currencySelected={this.state.currencySelected}
            />
          ) : null}
          {this.state.openApprovalModal ? (
            <ApprovalModal
              open={this.state.openApprovalModal}
              handleApprovalModalToggle={this.handleApprovalModalToggle}
              handlModalToggle={this.handlModalToggle}
              user_data={this.state.user_data}
              packageId={
                this.props.appreview.userinfo.transaction_details
                  ? this.props.appreview.userinfo.transaction_details
                      .updated_package._id
                  : this.state.currencySelected
                  ? this.props.appreview.crs_pkgs.filter(
                      (pkg) => pkg.curr == this.state.currencySelected
                    )[0]._id
                  : this.props.appreview.crs_pkgs[0]._id
              }
              packages={this.props.appreview.crs_pkgs}
              approvalType={this.state.approvalType}
              headIndex={this.state.headIndex}
              otpRequired={this.state.applicationOtpRequired}
              applicationDetail={this.props.applicationDetail}
            />
          ) : null}
          <Modal
            title="Application Review"
            visible={this.props.open}
            onCancel={this.closeModal}
            width="960px"
            className="applicationmodal"
          >
            <div>
              <div className="row no-margin" style={{ padding: 24 }}>
                <div className="col-12 col-md-8 no-margin no-padding ">
                  <div
                    className="ar-modal-main-head"
                    style={{ padding: "0px 0px 18px 0px", margin: 0 }}
                  >
                    {this.props.appreview.userinfo.user_name}
                  </div>
                  <div className="crs-box">
                    {this.props.appreview.userinfo.crs_name}
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    <span style={{ padding: "0px 16px 0px 0px" }}>
                      UID : {this.props.appreview.userinfo.uid}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "8px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <img src={callicon} />
                    <span style={{ padding: "0px 16px 0px 8px" }}>
                      {this.props.appreview.userinfo.phone_no}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "8px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <img src={envelopeicon} />
                    <span style={{ padding: "0px 16px 0px 8px" }}>
                      {this.props.appreview.userinfo.email[0]}{" "}
                      {`(Pegasus Email)`}
                    </span>
                  </div>
                  {this.props.appreview.userinfo.applicationEmail && (
                    <div
                      style={{
                        marginTop: "8px",
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <img src={envelopeicon} />
                      <span
                        style={{
                          padding: "0px 16px 0px 8px",
                          color: "#055646",
                        }}
                      >
                        {this.props.appreview.userinfo.applicationEmail}{" "}
                        {`(Application Email)`}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-4 no-margin no-padding">
                  <div
                    className={
                      this.props.appreview.userinfo.app_status == 3
                        ? "approved-clr"
                        : this.props.appreview.userinfo.app_status == 4
                        ? "reject-clr"
                        : this.props.appreview.userinfo.app_status == 2
                        ? "shortlist-clr"
                        : "pending-clr"
                    }
                  >
                    {
                      APPLICATION_STATUS[
                        this.props.appreview.userinfo.app_status
                      ]
                    }
                  </div>
                  <div style={{ fontSize: 12 }} className="sub_date">
                    Submitted: {this.props.appreview.userinfo.sub_date}
                  </div>
                  {this.props.appreview?.userinfo.coursePgIdsAllowedDownload &&
                    this.props.appreview?.userinfo.coursePgIdsAllowedDownload?.includes(
                      this.props.appreview?.crs_pg_id._id
                    ) && (
                      <div style={{ marginTop: "25px" }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <button
                          className="btn"
                          disabled={this.state.downloadInProgress}
                          style={{
                            background: "rgba(48, 185, 6, 0.05)",
                            border: "1px solid #30b906",
                            borderRadius: "5px",
                            float: "right",
                            color: "#2fb805",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                          onClick={this.handleApplicationPDFonClick}
                        >
                          Download Application PDF
                        </button>
                      </div>
                    )}
                </div>
              </div>
              <Row
                className="row no-margin"
                style={{
                  borderTop: "2px solid rgba(33, 42, 57, 0.25)",
                  padding: "0px 24px",
                }}
              >
                {this.state.schemaObjects &&
                  this.state.schemaObjects.map((obj, idx) => {
                    if (obj.type == 2) {
                      return null;
                    }
                    // console.log({ obj });
                    return (
                      <Row className="row no-margin" style={{ width: "100%" }}>
                        <div
                          className="row no-margin"
                          style={{
                            padding: "8px",
                            margin: "4px",
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            border: "#3C485280 solid 2px",
                            borderRadius: "2px",
                            width: "100%",
                          }}
                        >
                          <Col
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {obj.schemaDisplayName || obj.schemaName}
                          </Col>
                          {obj.type != 2 ? (
                            <Button
                              style={{
                                background: obj.isOpen ? "#FF3A44" : "#67C15E",
                                color: "white",
                                fontWeight: "bold",
                              }}
                              onClick={() => {
                                this.toggleSchemaObject(idx);
                              }}
                            >
                              {obj.isOpen ? "Hide" : "Show"}
                            </Button>
                          ) : null}
                        </div>
                        {obj.isOpen && obj.type == 1 && (
                          <Form
                            schema={obj.Edituischema}
                            uiSchema={obj.Viewuischema}
                            formData={
                              this.props.appreview.userinfo.form_data[
                                obj.stageSlug
                              ]
                            }
                            disabled={true}
                            children={true}
                            widgets={{
                              FileWidget: this.fileUpload,
                            }}
                          />
                        )}
                        <div>
                          {obj.isOpen &&
                            obj.type == 3 &&
                            this.props.appreview.userinfo.quizzesBySlug[
                              obj.stageSlug
                            ].map((qz) => {
                              return (
                                <div style={{ padding: "20px 0" }}>
                                  <div
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "600",
                                      lineHeight: "30px",
                                    }}
                                  >
                                    {qz.name}
                                  </div>
                                  {this.props.appreview.userinfo.form_data[
                                    obj.stageSlug
                                  ] &&
                                  this.props.appreview.userinfo.form_data[
                                    obj.stageSlug
                                  ][qz.quizId] &&
                                  this.props.appreview.userinfo.form_data[
                                    obj.stageSlug
                                  ][qz.quizId].length ? (
                                    <div>
                                      <table>
                                        <thead>
                                          <tr>
                                            <th
                                              style={{
                                                minWidth: "60px",
                                                textAlign: "center",
                                              }}
                                            >
                                              S. No.
                                            </th>
                                            <th
                                              style={{
                                                minWidth: "200px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Started At
                                            </th>
                                            <th
                                              style={{
                                                minWidth: "200px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Submitted At
                                            </th>
                                            <th
                                              style={{
                                                wminWidth: "200px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Score
                                            </th>
                                            <th
                                              style={{
                                                wminWidth: "200px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Status
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.props.appreview.userinfo.form_data[
                                            obj.stageSlug
                                          ][qz.quizId].map((dt, index) => {
                                            return (
                                              <tr>
                                                <td
                                                  style={{
                                                    minWidth: "60px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={{
                                                    minWidth: "200px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {moment(dt.startTime).format(
                                                    "LLL"
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    minWidth: "200px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {dt.endTime
                                                    ? moment(dt.endTime).format(
                                                        "LLL"
                                                      )
                                                    : "NA"}
                                                </td>
                                                <td
                                                  style={{
                                                    minWidth: "200px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {`${dt.marks_obtained}/${dt.total_marks}`}
                                                </td>
                                                <td
                                                  style={{
                                                    minWidth: "200px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {typeof dt.passed === "string"
                                                    ? dt.passed
                                                    : dt.passed
                                                    ? "Pass"
                                                    : "Fail"}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                      {APPLICATION_STATUS[
                                        this.props.appreview.userinfo.app_status
                                      ] == "Pending" ||
                                      APPLICATION_STATUS[
                                        this.props.appreview.userinfo.app_status
                                      ] == "Not Filled" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {this.checkAttemptAvailability(
                                            this.props.appreview.userinfo
                                              .form_data[obj.stageSlug][
                                              qz.quizId
                                            ],
                                            this.props.appreview.userinfo
                                              .currentAttempts[qz.quizId],
                                            this.props.appreview.userinfo
                                              .maxAttempts,
                                            this.props.appreview.userinfo
                                              .user_app_id,
                                            obj.stageSlug,
                                            qz.quizId
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div>No attempts found for this test</div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </Row>
                    );
                  })}
              </Row>
              <TransactionDetails
                transaction={this.props.appreview.userinfo.transaction_details}
                package={
                  this.props.appreview.userinfo.transaction_details
                    ? this.props.appreview.crs_pkgs.find((pkg) => {
                        pkg._id ==
                          this.props.appreview.userinfo.transaction_details
                            .package_id;
                      })
                    : this.props.appreview.crs_pkgs[0]
                }
                packages={this.props.appreview.crs_pkgs}
                scholarship={
                  this.props.appreview.userinfo.transaction_details &&
                  this.props.appreview.userinfo.scholarship_amount &&
                  this.props.appreview.userinfo.scholarship_amount[
                    this.props.appreview.userinfo.transaction_details
                      .updated_package._id
                  ]
                    ? this.props.appreview.userinfo.scholarship_amount[
                        this.props.appreview.userinfo.transaction_details
                          .updated_package._id
                      ].amount
                    : 0
                }
                scholarship_object={
                  this.props.appreview.userinfo.scholarship_amount
                }
                discount={this.props.appreview.userinfo.advisorAppliedPromocode}
                overridesFound={this.props.appreview.userinfo.overridesFound}
                updateCurrency={this.updateCurrency}
                currencySelected={this.state.currencySelected}
                loading={this.props.loading}
              />
              <div
                className="row no-margin "
                style={{
                  padding: "20px 24px 40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {(APPLICATION_STATUS[
                    this.props.appreview.userinfo.app_status
                  ] == "Pending" ||
                    APPLICATION_STATUS[
                      this.props.appreview.userinfo.app_status
                    ] == "Not Filled") &&
                    this.props.appreview.userinfo.enableScholarshipDiscount &&
                    this.checkScholashipButtonVisibility() && (
                      <button
                        className="ar-vw-app"
                        style={{
                          width: "fit-content",
                          float: "right",
                          padding: "8px 16px",
                          margin: "auto",
                        }}
                        onClick={this.HandleViewScholarshipButton}
                      >
                        Apply Scholarship
                      </button>
                    )}
                  {this.props.appreview.userinfo.enablePreemptiveCodeDiscount &&
                    this.isPromocodeAvailable() && (
                      <button
                        className="ar-vw-app"
                        style={{
                          width: "fit-content",
                          float: "right",
                          padding: "8px 16px",
                          margin: "auto",
                        }}
                        onClick={() => {
                          this.setState({
                            openApplyPromocodeModal: true,
                          });
                        }}
                      >
                        Apply Promocode
                      </button>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "auto",
                    justifyContent: "center",
                  }}
                >
                  {this.checkVisibility("34") &&
                    APPLICATION_STATUS[
                      this.props.appreview.userinfo.app_status
                    ] == "Approved" &&
                    this.checkResendMainPaymentPageButtonAvailable() && (
                      <div
                        style={{
                          width: "fit-content",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          margin: "auto",
                          marginTop: "36px",
                        }}
                      >
                        <button
                          className="ar-vw-app"
                          style={{
                            width: "fit-content",
                            float: "right",
                            padding: "8px 16px",
                          }}
                          onClick={() => {
                            this.sendPaymentLink(false);
                          }}
                        >
                          Resend Main Payment Page Link to the Student
                        </button>
                        <div
                          className="tooltip_link"
                          onMouseLeave={() =>
                            this.setState({
                              link_copy_text: "Copy Link",
                            })
                          }
                        >
                          <CopyToClipboard
                            text={this.getPaymentLink()}
                            onCopy={() =>
                              this.setState({
                                link_copy_text: "Copied!",
                              })
                            }
                          >
                            <Tooltip title={this.state.link_copy_text}>
                              <ContentCopyIcon />
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      </div>
                    )}
                  {this.checkVisibility("34") &&
                    APPLICATION_STATUS[this.props.applicationDetail.stts] ==
                      "Approved" &&
                    this.checkResendInstallmentPaymentPageButtonAvailable() && (
                      <div
                        style={{
                          width: "fit-content",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          margin: "auto",
                          marginTop: "36px",
                        }}
                      >
                        <button
                          className="ar-vw-app"
                          style={{
                            width: "fit-content",
                            float: "right",
                            padding: "8px 16px",
                          }}
                          onClick={() => {
                            this.sendPaymentLink(true);
                          }}
                        >
                          Resend Installment Payment Page Link to the Student
                        </button>
                        <div
                          className="tooltip_link"
                          onMouseLeave={() =>
                            this.setState({
                              link_copy_text: "Copy Link",
                            })
                          }
                        >
                          <CopyToClipboard
                            text={this.getInstallmentLink()}
                            onCopy={() =>
                              this.setState({
                                link_copy_text: "Copied!",
                              })
                            }
                          >
                            <Tooltip title={this.state.link_copy_text}>
                              <ContentCopyIcon />
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      </div>
                    )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "36px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      margin: "auto",
                    }}
                  >
                    <button
                      className="ar-vw-app"
                      style={{
                        width: "fit-content",
                        float: "right",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        this.copyApplicationLink(true);
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {this.state.application_link_copy_text != "Copied"
                          ? "Copy Application Link"
                          : "Application Link Copied"}
                        <div
                          className="tooltip_link"
                          onMouseLeave={() =>
                            this.setState({
                              application_link_copy_text: "Copy Link",
                            })
                          }
                        >
                          <CopyToClipboard
                            text={this.getApplicationLink()}
                            onCopy={() =>
                              this.setState({
                                application_link_copy_text: "Copied!",
                              })
                            }
                          >
                            <Tooltip
                              title={this.state.application_link_copy_text}
                            >
                              <ContentCopyIcon />
                            </Tooltip>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                {this.checkVisibility("34") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "36px",
                      width: "100%",
                    }}
                  >
                    {this.props.appreview.userinfo.app_status == 1 ? (
                      <div className="col-32 col-sm-12 col-md-4 no-margin no-padding">
                        <button
                          className="btn-reject-cand"
                          onClick={(e) => this.handleApplicationStatus(4, e)}
                        >
                          Reject
                        </button>
                      </div>
                    ) : null}
                    {this.props.appreview.userinfo.app_status == 1 ? (
                      this.checkOtpApprovalAvailable(
                        this.props.appreview.userinfo
                      ) ? (
                        <div className="col-12 col-sm-12 col-md-4 no-margin no-padding">
                          <button
                            className="btn-approve-cand"
                            onClick={(e) => this.handleApplicationStatus(6, e)}
                          >
                            Request Approval
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 col-sm-12 col-md-4 no-margin no-padding">
                          <button
                            className="btn-approve-cand"
                            onClick={(e) => this.handleApplicationStatus(3, e)}
                          >
                            Approve
                          </button>
                        </div>
                      )
                    ) : null}
                  </div>
                )}
                {this.checkVisibility("34") &&
                  (APPLICATION_STATUS[
                    this.props.appreview.userinfo.app_status
                  ] == "Approved" ||
                    APPLICATION_STATUS[
                      this.props.appreview.userinfo.app_status
                    ] == "Rejected") &&
                  this.state.schemaObjects &&
                  this.isApprovalAvailable() && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // marginTop: "16px",
                        width: "100%",
                      }}
                    >
                      <div className="col-12 col-sm-12 col-md-4 no-margin no-padding">
                        <button
                          className="btn-revert-cand"
                          onClick={this.handleRevertApplicationStatus}
                        >
                          {this.state.applicationOtpRequired
                            ? "Request Application Revert"
                            : "Revert Application"}
                        </button>
                      </div>
                    </div>
                  )}
                <div style={{ marginTop: "36px" }}>
                  <b>Notes :</b>
                  <ol>
                    <li>
                      All application and transactions updates are sent to the
                      student on Mobile Number, Pegasus Email and Application
                      Email.
                    </li>
                    {this.checkVisibility("34") &&
                    (APPLICATION_STATUS[
                      this.props.appreview.userinfo.app_status
                    ] == "Approved" ||
                      APPLICATION_STATUS[
                        this.props.appreview.userinfo.app_status
                      ] == "Rejected") ? (
                      <>
                        {APPLICATION_STATUS[
                          this.props.appreview.userinfo.app_status
                        ] == "Approved" && (
                          <li>
                            Once you approve the application the payment link
                            automatically goes by-default.
                          </li>
                        )}
                        <li>
                          Application approved status can only be reverted
                          untill student has not done a payment after the
                          approval.
                        </li>
                      </>
                    ) : null}
                    {this.props.appreview.userinfo
                      .enablePreemptiveCodeDiscount && (
                      <li>
                        Promocodes created for a specific payment type (Full,
                        Installment, Loan) will not work, please use promocodes
                        applicable to all payment types.
                      </li>
                    )}
                    {this.props.appreview.userinfo
                      .enablePreemptiveCodeDiscount && (
                      <li>
                        Once a promocode is applied, only a promocode with a
                        higher amount can now be applied.
                      </li>
                    )}
                  </ol>
                </div>
              </div>
              {this.state.openScholarshipModal ? (
                <ViewScholarshipModal
                  open={this.state.openScholarshipModal}
                  handleViewScholarshipModalToggle={
                    this.handleViewScholarshipModalToggle
                  }
                  applicationDetail={this.props.applicationDetail}
                  handlModalToggle={this.handlModalToggle}
                  currencySelected={this.state.currencySelected}
                />
              ) : null}
            </div>
          </Modal>
        </div>
      );
    } else {
      return <div>{/* <Loader loading/> */}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
    permissions: state.login.permissions,
    resources: state.login.resources,
    loading: state.arloading,
  };
};

const mapDispatchToProps = {
  fetchApplicationDetail,
  deleteUserInfoData,
  updateAssessmentAmount,
  sendPaymentLinkToUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewApplicationModal);
